import { Link } from "react-router-dom";

import "./LandingPage.scss";
import "../GamePage/GamePage.scss";

import Mushroom from "../../assets/icons/gift-mushroom.png";
import Frog from "../../assets/icons/gift-frog.png";
import Strawberry from "../../assets/icons/strawberry.png";
import ClickSound from "../../assets/audio/click.wav";

function LandingPage() {
  const playClickSound = () => {
    const audio = new Audio(ClickSound);
    audio.play();
  };

  return (
    <>
      <section className="landing-page">
        <section className="landing-page__container">
          <h1 className="landing-page__title">🍃 Welcome to Bug Forest! 🍃</h1>
          <p className="landing-page__copy">Click below to explore</p>
          <Link to="/play">
            <button className="landing-page__enter" onClick={playClickSound}>Enter</button>
          </Link>
        </section>
        <section className="social-container">
          <a
            className="social-container__link"
            href="https://www.rebekahheppner.net"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="social-container__image"
              src={Mushroom}
              alt="mushroom icon"
            ></img>
            <button className="social-container__button" onClick={playClickSound}>Website</button>
          </a>
          <a
            className="social-container__link"
            href="https://ca.linkedin.com/in/rebekah-heppner"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="social-container__image"
              src={Frog}
              alt="mushroom icon"
            ></img>
            <button className="social-container__button" onClick={playClickSound}>LinkedIn</button>
          </a>
          <a
            className="social-container__link"
            href="https://www.instagram.com/rebekahheppner/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="social-container__image"
              src={Strawberry}
              alt="mushroom icon"
            ></img>
            <button className="social-container__button onClick={playClickSound}">Instagram</button>
          </a>
        </section>
      </section>
    </>
  );
}

export default LandingPage;
