import DownloadButton from "../DownloadButton/DownloadButton";
import "./Inventory.scss";

function Inventory({ collectedGifts }) {
  return (
    <>
      <section className="inventory-container">
        <ul className="inventory">
          {collectedGifts.map((giftsData, index) => (
            <li key={index}>
              <img
                src={giftsData.iconUrl}
                alt={giftsData.alt}
                className={`gift-item gift-item--${giftsData.type}`}
              />
            </li>
          ))}
        </ul>
        <section className="button-container">
          <DownloadButton collectedGifts={collectedGifts} />
        </section>
      </section>
    </>
  );
}

export default Inventory;
