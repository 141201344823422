import { useGLTF } from '@react-three/drei'

export function Vine() {
  const { nodes, materials } = useGLTF('assets/models/vine-transformed.glb')
  return (
    <group dispose={null}>
      <mesh geometry={nodes.largeVine003.geometry} material={materials.ground1} position={[-6.543, 0.778, -8.447]} rotation={[0.059, -0.195, 0.741]} scale={[0.229, 0.177, 0.241]} />
    </group>
  )
}

useGLTF.preload('/vine-transformed.glb')
