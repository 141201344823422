import { useGLTF } from '@react-three/drei'
import { useRef, useEffect } from 'react'

export function Characters({ registerEnvironmentObject }) {
  const charactersRef=useRef();
  const { nodes, materials } = useGLTF('assets/models/characters-transformed.glb')

  useEffect(() => {
    if (charactersRef.current) {
      registerEnvironmentObject(charactersRef.current);
    }
  }, [registerEnvironmentObject]);
  return (
    <group ref={charactersRef} dispose={null}>
      <group position={[-6.673, 0.074, -4.679]} rotation={[0, -1.481, 0]} scale={1.171}>
        <mesh geometry={nodes.Circle003.geometry} material={materials['strawberry.001']} />
        <mesh geometry={nodes.Circle003_1.geometry} material={materials['strawberry.002']} />
        <mesh geometry={nodes.Circle003_2.geometry} material={materials['strawberry.003']} />
        <mesh geometry={nodes.Circle003_3.geometry} material={materials['strawberry.004']} />
        <mesh geometry={nodes.Circle003_4.geometry} material={materials['mushroom.001']} />
        <mesh geometry={nodes.Circle003_5.geometry} material={materials['mushroom.002']} />
        <mesh geometry={nodes.Circle003_6.geometry} material={materials.PaletteMaterial001} />
      </group>
    </group>
  )
}

useGLTF.preload('/characters-transformed.glb')
