import { EffectComposer } from "@react-three/postprocessing";
import { Bloom } from "@react-three/postprocessing";
import { KernelSize, Resolution } from "postprocessing";

function PostProcessing() {
  return (
    <EffectComposer>
      <Bloom
        intensity={2.0} 
        kernelSize={KernelSize.LARGE} 
        luminanceThreshold={0.9} 
        luminanceSmoothing={0.2} 
        mipmapBlur={true}
        resolutionX={Resolution.AUTO_SIZE} 
        resolutionY={Resolution.AUTO_SIZE} 
      />
    </EffectComposer>
  );
}

export default PostProcessing;
