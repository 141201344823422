import { useGLTF } from '@react-three/drei'
import { useRef, useEffect } from 'react'

export function Ladybug({ registerEnvironmentObject }) {
  const ladybugRef=useRef();
  const { nodes, materials } = useGLTF('assets/models/ladybug-transformed.glb')

  useEffect(() => {
    if (ladybugRef.current) {
      registerEnvironmentObject(ladybugRef.current);
    }
  }, [registerEnvironmentObject]);

  return (
    <group ref={ladybugRef} dispose={null}>
      <group position={[1.184, 0.138, -4.12]} rotation={[0, -1.168, 0]} scale={0.558}>
        <mesh geometry={nodes.Sphere013.geometry} material={materials.black} />
        <mesh geometry={nodes.Sphere013_1.geometry} material={materials.Cloud} />
        <mesh geometry={nodes.Sphere013_2.geometry} material={materials['testColor.002']} />
        <mesh geometry={nodes.Sphere013_3.geometry} material={materials['strawberry.001']} />
        <mesh geometry={nodes.Sphere013_4.geometry} material={materials.ladybug} />
      </group>
    </group>
  )
}

useGLTF.preload('/ladybug-transformed.glb')
