import { GiftFrog } from "./Gift-frog";
import { GiftBug } from "./Gift-bug";
import { GiftFairy } from "./Gift-fairy";
import { GiftFlower } from "./Gift-flower";
import { GiftLady } from "./Gift-lady";
import { GiftMushroom } from "./Gift-mushroom";

function Gifts({ collectedGifts, playerPosition }) {

  return (
    <>
      <GiftBug collectedGifts={collectedGifts} playerPosition={playerPosition} />
      <GiftFairy collectedGifts={collectedGifts} playerPosition={playerPosition} />
      <GiftFlower collectedGifts={collectedGifts} playerPosition={playerPosition} />
      <GiftFrog collectedGifts={collectedGifts} playerPosition={playerPosition}/>
      <GiftLady collectedGifts={collectedGifts} playerPosition={playerPosition} />
      <GiftMushroom collectedGifts={collectedGifts} playerPosition={playerPosition} />
    </>
  );
}

export default Gifts;
