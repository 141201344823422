import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import { Physics } from "@react-three/rapier";
import { useState } from "react";
import { useRef } from "react";

import "./Game.scss";

import EnvironmentModels from "../Environment/EnvironmentModels";
import Gifts from "../Gifts/Gifts";
import Lighting from "../Lighting/Lighting";
import PostProcessing from "../PostProcessing/PostProcessing";
import { Player } from "../Player/Player";

function Game({ setCollectedGifts, collectedGifts }) {
  const environmentRefs = useRef([]);
  const registerEnvironmentObject = (ref) => {
    if (ref && !environmentRefs.current.includes(ref)) {
      environmentRefs.current.push(ref);
      // console.log('Registered Environment Object:', ref);
    }
  };
  const [playerPosition, setPlayerPosition] = useState([0, 0, 0]);

  // Updates player position
  const handlePlayerPositionChange = (position) => {
    setPlayerPosition(position);
  };

  return (
    <div className="game-container">
      <Canvas>
        <Physics gravity={[0, 0, 0]}>
          <Lighting />
          <PostProcessing />
          <mesh>
            <sphereGeometry args={[100, 32, 32]} />
            <meshBasicMaterial color={0x2b2a49} side={THREE.BackSide} />
          </mesh>
          <EnvironmentModels registerEnvironmentObject={registerEnvironmentObject} />
          <Gifts
            setCollectedGifts={setCollectedGifts}
            collectedGifts={collectedGifts}
            playerPosition={playerPosition}
          />
          <Player
            environmentObjects={environmentRefs.current}
            setCollectedGifts={setCollectedGifts}
            collectedGifts={collectedGifts}
            onPositionChange={handlePlayerPositionChange}
          />
        </Physics>
      </Canvas>
    </div>
  );
}

export default Game;
