import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function GiftFairy({ onCollected, collectedGifts, playerPosition }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/assets/models/gift-fairy-transformed.glb"
  );
  const { actions } = useAnimations(animations, group);
  const scaleFactor = 0.5;
  const scaleFactorCollected = 0;
  const startingPosition = [-1.2, 0.5, -8.9];

  // Start playing the animation when the component mounts
  React.useEffect(() => {
    if (actions && actions["SphereAction"]) {
      actions["SphereAction"].play();
    }
  }, [actions]);

  // Determine if the gift is collected
  const isCollected = collectedGifts.some((gift) => gift.name === "Gift-6");

  // Calculate the distance between two 3D points
  const calculateDistance = (point1, point2) => {
    const dx = point1[0] - point2[0];
    const dy = point1[1] - point2[1];
    const dz = point1[2] - point2[2];
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  };

  // Check for gift collection when the player intercepts bubble
  useEffect(() => {
    const distance = calculateDistance(startingPosition, playerPosition);
    if (distance < 0.15 && !isCollected) {
      onCollected();
    }
  }, [onCollected, isCollected, playerPosition]);
  return (
    <group
      ref={group}
      dispose={null}
      position={startingPosition}
      scale={[
        isCollected ? scaleFactorCollected : scaleFactor,
        isCollected ? scaleFactorCollected : scaleFactor,
        isCollected ? scaleFactorCollected : scaleFactor,
      ]}
    >
      <group name="Scene">
        <group name="Sphere" scale={scaleFactor}>
          <mesh
            name="Sphere_1"
            geometry={nodes.Sphere_1.geometry}
            material={materials.clear}
          />
          <mesh
            name="Sphere_2"
            geometry={nodes.Sphere_2.geometry}
            material={materials["gift-frog"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/gift-fairy-transformed.glb");
