import "./DownloadButton.scss";
import JSZip from "jszip";
import ClickSound from "../../assets/audio/click.wav";

function DownloadButton({ collectedGifts }) {
  const giftUrls = collectedGifts.map((gift) => gift.giftUrl);

  const playClickSound = () => {
    const audio = new Audio(ClickSound);
    audio.play();
  };

  function createZip() {
    const zip = new JSZip();
    const fetchPromises = [];

    // Add filtered images to the zip
    giftUrls.forEach((giftUrl, index) => {
      const gift = collectedGifts[index];
      if (!gift) return;

      const fileName = `${gift.name}.jpg`;

      const fetchPromise = fetch(giftUrl)
        .then((response) => {
          if (!response.ok) {
            console.error(
              `Error fetching image ${fileName}: ${response.statusText}`
            );
            return null;
          }
          return response.blob();
        })
        .then((blob) => {
          if (blob) {
            // Add the image to the zip with the gift name as the filename
            zip.file(fileName, blob);
          }
        })
        .catch((error) => {
          console.error(`Error fetching image ${fileName}: ${error.message}`);
        });

      fetchPromises.push(fetchPromise);
    });

    // Add the bonus gift to the zip
    const bonusGiftUrl = "/assets/gifts/Bonus.jpg";
    const bonusGiftFileName = "Bonus.jpg";
    const bonusGiftFetchPromise = fetch(bonusGiftUrl)
      .then((response) => {
        if (!response.ok) {
          console.error(
            `Error fetching bonus gift image: ${response.statusText}`
          );
          return null;
        }
        return response.blob();
      })
      .then((blob) => {
        if (blob) {
          // Add the bonus gift to the zip
          zip.file(bonusGiftFileName, blob);
        }
      })
      .catch((error) => {
        console.error(`Error fetching bonus gift image: ${error.message}`);
      });

    fetchPromises.push(bonusGiftFetchPromise);

    // Wait for all fetch requests to complete
    Promise.all(fetchPromises).then(() => {
      // Generate the zip file
      zip.generateAsync({ type: "blob" }).then((content) => {
        // Save or download the zip file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "Bug-Forest-Gifts.zip";
        link.click();
      });
    });
  }

  const handleDownloadClick = () => {
    playClickSound();
    createZip();
  };

  return (
    <button className="download-button" onClick={handleDownloadClick}>
      DOWNLOAD GIFTS
    </button>
  );
}

export default DownloadButton;
