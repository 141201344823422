import { Ground } from "./Ground";
import { Trees } from "./Trees";
import { Characters } from "./Characters";
import { Grasses } from "./Grasses";
import { Flowers } from "./Flowers";
import { Vine } from "./Vine";
import { Ladybug } from "./Ladybug";

function EnvironmentModels({ registerEnvironmentObject }) {
  return (
    <>
      <Trees registerEnvironmentObject={registerEnvironmentObject} />
      <Ground registerEnvironmentObject={registerEnvironmentObject} />
      <Grasses registerEnvironmentObject={registerEnvironmentObject} />
      <Ladybug registerEnvironmentObject={registerEnvironmentObject} />
      <Flowers registerEnvironmentObject={registerEnvironmentObject} />
      <Vine registerEnvironmentObject={registerEnvironmentObject} />
      <Characters registerEnvironmentObject={registerEnvironmentObject} />
    </>
  );
}

export default EnvironmentModels;
