import { useGLTF } from '@react-three/drei'
import { useEffect, useRef } from 'react'

export function Grasses({registerEnvironmentObject}) {
  const grassesRef=useRef();
  const { nodes, materials } = useGLTF('assets/models/grasses-transformed.glb')

  useEffect(()=> {
    if (grassesRef.current) {
      registerEnvironmentObject(grassesRef.current);
    }
  }, [registerEnvironmentObject]);

  return (
    <group ref={grassesRef} dispose={null}>
      <group position={[1.296, -0.091, -2.729]} rotation={[1.767, 0.261, 2.938]} scale={[0.185, 0.131, 0.111]}>
        <mesh geometry={nodes.Plane026.geometry} material={materials['strawberryGreen.001']} />
        <mesh geometry={nodes.Plane026_1.geometry} material={materials['testColor.004']} />
        <mesh geometry={nodes.Plane026_2.geometry} material={materials.ground1} />
        <mesh geometry={nodes.Plane026_3.geometry} material={materials.Grass} />
      </group>
    </group>
  )
}

useGLTF.preload('/grasses-transformed.glb')
