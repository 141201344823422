import { useGLTF } from '@react-three/drei'
import React, { useEffect, useRef } from "react";

export function Flowers({ registerEnvironmentObject }) {
  const flowersRef = useRef();
  const { nodes, materials } = useGLTF('assets/models/flowers-transformed.glb')

   // Register the flowers mesh for collision detection
  useEffect(() =>{
    if (flowersRef.current) {
      registerEnvironmentObject(flowersRef.current);
    }
  }, [registerEnvironmentObject]);

  return (
    <group ref={flowersRef} dispose={null}>
      <group position={[-8.745, 0.212, -5.856]} rotation={[Math.PI / 2, 0, 0]} scale={0.304}>
        <mesh geometry={nodes.Top_Sphere019.geometry} material={materials['testColor.008']} material-roughness={1} 
        material-reflectivity={0} />
        <mesh geometry={nodes.Top_Sphere019_1.geometry} material={materials.butterfly} material-roughness={1} 
        material-reflectivity={0}/>
        <mesh geometry={nodes.Top_Sphere019_2.geometry} material={materials.Lilypad} material-roughness={1} 
        material-reflectivity={0}/>
        <mesh geometry={nodes.Top_Sphere019_3.geometry} material={materials['leaves.002']} material-roughness={1} 
        material-reflectivity={0}/>
      </group>
    </group>
  )
}

useGLTF.preload('/flowers-transformed.glb')
