import { useGLTF } from '@react-three/drei'
import React, { useEffect, useRef } from "react";

export function Trees({ registerEnvironmentObject }) {
  const treesRef = useRef();
  const { nodes, materials } = useGLTF('assets/models/trees-transformed.glb')

  useEffect(() =>{
    if (treesRef.current) {
      registerEnvironmentObject(treesRef.current);
    }
  }, [registerEnvironmentObject]);

  return (
    <group ref={treesRef} dispose={null}>
      <mesh geometry={nodes.Vine001.geometry} material={materials.ground1} position={[-2.026, 1.518, -4.601]} rotation={[Math.PI / 2, 0, 0.675]} scale={[0.561, 0.561, 0.764]} />
      <group position={[5.156, 0.382, -8.361]} rotation={[Math.PI, -0.237, 0]} scale={[-2.431, -2.804, -2.431]}>
        <mesh geometry={nodes.Cylinder012.geometry} material={materials.Bush1} material-roughness={1} 
        material-reflectivity={0} />
        <mesh geometry={nodes.Cylinder012_1.geometry} material={materials.Bark} material-roughness={1} 
        material-reflectivity={0} />
        <mesh geometry={nodes.Cylinder012_2.geometry} material={materials['leaves.002']} material-roughness={1} 
        material-reflectivity={0} />
      </group>
    </group>
  )
}

useGLTF.preload('/trees-transformed.glb')
