import "./Help.scss";
import Bubble from "../../assets/images/bubble.png";
import Keys from "../../assets/images/arrow-keys.png";

function Help() {
  return (
    <>
      <section>
        <section className="help-screen">
          <h4 className="help-screen__text--title">
            🐞 Welcome to Bug Forest! 🐞
          </h4>
          <p>
            You are a fairy foraging in the wild. Use the arrow keys to navigate
            through the environment.
          </p>
          <img className="help-screen__image" src={Keys}></img>
          <p> Collect gift bubbles along the way to keep as souvenirs.</p>
          <img className="help-screen__image" src={Bubble}></img>
          <p>
            When you're ready to leave, click the download button to see your
            forest gifts!
          </p>
        </section>
      </section>
    </>
  );
}

export default Help;
