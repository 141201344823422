import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground({ registerEnvironmentObject }) {
  const groundRef=useRef();
  const { nodes, materials } = useGLTF("assets/models/ground-transformed.glb");

  useEffect(() => {
    if (groundRef.current) {
      registerEnvironmentObject(groundRef.current);
    }
  }, [registerEnvironmentObject]);

  return (
    <group ref={groundRef} dispose={null}>
      <mesh geometry={nodes.Plane.geometry} material={materials["water.001"]} />
      <mesh geometry={nodes.Plane_1.geometry} material={materials.Treeboard2} />
      <mesh
        geometry={nodes.Plane_2.geometry}
        material={materials["Water.001"]}
        material-roughness={1} 
        material-reflectivity={0}
      />
      <mesh geometry={nodes.Plane_3.geometry} material={materials.Cloud} />
      <mesh
        geometry={nodes.Plane_4.geometry}
        material={materials["ground1.001"]}
        material-roughness={1} 
        material-reflectivity={0}
      />
    </group>
  );
}

useGLTF.preload("/ground-transformed.glb");
