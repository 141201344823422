function Lighting() {
  return (
    <>
      <pointLight position={[5, 3, -6]} color={0xa3fff8} intensity={15} />
      <pointLight position={[-3, 3, -3.5]} color={0xffdba7} intensity={15} />
      <pointLight position={[-6, 3, -7]} color={0xffa2c7} intensity={15} />
      <ambientLight intensity={0.5} />
    </>
  );
}

export default Lighting;
