import React, { useRef, useState, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

import giftsData from "../../data/gifts.json";
import SparkleSound from "../../assets/audio/sparkle.mp3";

// CONSTANTS
const SPEED = 0.028;
const ROTATION_SPEED = 0.022;
const SCALE_FACTOR = 0.23;
const STARTING_POSITION = [-3.5, 0.48, -3];

// THIRD PERSON CAMERA
function ThirdPersonCamera({ playerRef }) {
  const { camera } = useThree();
  camera.near = 0.4;

   // Desired focal length in mm (change this as needed)
   const desiredFocalLength = 20; // Focal length in mm
   const sensorHeight = 24; // Example sensor height in mm
 
   // Calculate the new field of view based on the focal length
   const newFov = 2 * Math.atan((sensorHeight / 2) / desiredFocalLength) * (180 / Math.PI);
   camera.fov = newFov; // Set the camera's field of view
   camera.updateProjectionMatrix(); // Update the projection matrix
  

  useFrame(() => {
    if (playerRef.current) {
      // Offset the camera behind the player
      const offset = new THREE.Vector3(0, 0.3, 1);
      offset.applyQuaternion(playerRef.current.quaternion);
      const position = playerRef.current.position.clone().add(offset);

      // Set the camera position and look at the player
      camera.position.copy(position);
      camera.lookAt(playerRef.current.position);
    }
  });

  return null;
}

// PLAYER
export function Player({
  collectedGifts,
  setCollectedGifts,
  onPositionChange,
  environmentObjects
}) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/assets/models/playerAnimated-transformed.glb"
  );

  // ANIMATION
  const { actions } = useAnimations(animations, group);

  // Play animation when the component mounts
  React.useEffect(() => {
    if (actions && actions["Fairy_Circle.006Action"]) {
      actions["Fairy_Circle.006Action"].play();
    }
  }, [actions]);

  // PLAYER NAVIGATION
  const [position, setPosition] = useState(STARTING_POSITION);
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [keysPressed, setKeysPressed] = useState({});

  const handleKeyDown = (event) => {
    setKeysPressed((keys) => ({ ...keys, [event.key]: true }));
  };

  const handleKeyUp = (event) => {
    setKeysPressed((keys) => ({ ...keys, [event.key]: false }));
  };

  // PLAYER NAVIGATION
const movePlayer = () => {
  const raycaster = new THREE.Raycaster();
  const forwardDirection = new THREE.Vector3(); // To hold movement direction
  const playerPosition = new THREE.Vector3(...position); // Use current player position
  let isMoving = false; // Track whether the player is moving
  
  // Determine rotation direction based on pressed keys
  if (keysPressed["ArrowLeft"]) {
    setRotation((prevRotation) => [0, prevRotation[1] + ROTATION_SPEED, 0]);
  }
  if (keysPressed["ArrowRight"]) {
    setRotation((prevRotation) => [0, prevRotation[1] - ROTATION_SPEED, 0]);
  }
  
  // Movement based on direction
  if (keysPressed["ArrowUp"] || keysPressed["ArrowDown"]) {
    // Determine movement direction
    const moveForward = keysPressed["ArrowUp"];
    const dx = (moveForward ? -SPEED : SPEED) * Math.sin(rotation[1]);
    const dz = (moveForward ? -SPEED : SPEED) * Math.cos(rotation[1]);
    
    // Set the direction vector
    forwardDirection.set(dx, 0, dz).normalize();
    
    // Check if the player is moving
    if (dx !== 0 || dz !== 0) {
      isMoving = true; // The player is moving
    }

    // Cast ray from player's current position in the direction they are moving
    raycaster.set(playerPosition, forwardDirection);
    
    // Replace 'environmentObjects' with your actual environment meshes
    const intersects = raycaster.intersectObjects(environmentObjects, true);
    
    if (intersects.length > 0 && intersects[0].distance < 0.3) {
      // Collision detected within 0.3 units, don't move
      ;
    } else {
      // No collision, update the player's position
      setPosition((prevPosition) => [
        prevPosition[0] + dx,
        prevPosition[1],
        prevPosition[2] + dz,
      ]);
    }
  }

  // Perform camera collision checks only if the player is moving
  if (isMoving) {
    const checkCameraCollision = () => {
      const raycaster = new THREE.Raycaster();
      const forwardDirection = new THREE.Vector3(0, 0, -1).applyQuaternion(group.current.quaternion);
    
      // Set the raycaster's origin to the camera's position
      raycaster.set(group.current.position, forwardDirection);
    
      // Check for intersections with the wall meshes
      const intersects = raycaster.intersectObjects(environmentObjects, true);
      
      if (intersects.length > 0) {
        // If there is an intersection, prevent camera rotation
        return true;
      }
      return false;
    };

    // Call the camera collision check
    checkCameraCollision();
  }
};

  // Attach event listeners for keydown and keyup events
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // GIFT COLLECTION
  // Calculate the distance between two 3D points
  const calculateDistance = (point1, point2) => {
    const dx = point1[0] - point2.x;
    const dy = point1[1] - point2.y;
    const dz = point1[2] - point2.z;
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  };

  const [playerGiftPositions, setPlayerGiftPositions] = useState([
    { name: "Gift-1", position: [-6.7, 0.5, -3] },
    { name: "Gift-2", position: [-7, 0.5, -7.5] },
    { name: "Gift-3", position: [2, 0.5, -2.3] },
    { name: "Gift-4", position: [4.4, 0.5, -5.3] },
    { name: "Gift-5", position: [7.5, 0.5, -7.3] },
    { name: "Gift-6", position: [-1.2, 0.5, -8.9] },
  ]);

  const checkGiftCollect = () => {
    for (const { name, position } of playerGiftPositions) {
      const distance = calculateDistance(position, group.current.position);
      if (distance < 0.17) {
        // console.log(`Player collected ${name} gift.`);
        const audio = new Audio(SparkleSound);
        audio.volume = 0.3;
        audio.play();
        // Find the gift data from JSON file by name
        const collectedGiftData = giftsData.gifts.find(
          (gift) => gift.name === name
        );
        if (collectedGiftData) {
          // If gift data is found, extract properties for collectedGifts state
          const { iconUrl, alt, giftUrl } = collectedGiftData;
          setCollectedGifts((prevCollectedGifts) => {
            const updatedCollectedGifts = [
              ...prevCollectedGifts,
              { name, type: getGiftType(name), iconUrl, alt, giftUrl },
            ];
            // console.log("Collected gifts:", updatedCollectedGifts);
            return updatedCollectedGifts;
          });
          // console.log(`Collected ${name} gift.`);
        }
        // Remove collected gift position from the array to prevent collecting it again
        setPlayerGiftPositions(
          playerGiftPositions.filter((gift) => gift.name !== name)
        );
      }
    }
  };

  const getGiftType = (giftName) => {
    if (giftName === "Gift-4" || giftName === "Gift-3") {
      return "special";
    } else {
      return "normal";
    }
  };

  
  useFrame(() => {
    movePlayer();
    checkGiftCollect();
    const currentPosition = group.current.position.toArray();
    onPositionChange(currentPosition);
    
  });

  return (
    <group
      ref={group}
      position={position}
      rotation={rotation}
      scale={[SCALE_FACTOR, SCALE_FACTOR, SCALE_FACTOR]}
      dispose={null}
    >
      <ThirdPersonCamera playerRef={group} />
      <group name="Scene">
        <group
          name="Fairy_Circle006"
          position={[0, -0.424, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <mesh
            name="Fairy_Circle006_1"
            geometry={nodes.Fairy_Circle006_1.geometry}
            material={materials.ground1}
          />
          <mesh
            name="Fairy_Circle006_2"
            geometry={nodes.Fairy_Circle006_2.geometry}
            material={materials["testColor.006"]}
          />
          <mesh
            name="Fairy_Circle006_3"
            geometry={nodes.Fairy_Circle006_3.geometry}
            material={materials.fairyWings}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/playerAnimated-transformed.glb");
