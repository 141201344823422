import "./LoadingScreen.scss";
import Ladybug from "../../assets/icons/gift-lady.png";
import { useEffect, useState } from "react";

function LoadingScreen() {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoading(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`loading-screen ${showLoading ? "show" : "hide"}`}>
      <img src={Ladybug} className="loading-screen__image"></img>
      <h1 className="loading-screen__text">Loading...</h1>
    </div>
  );
}

export default LoadingScreen;
